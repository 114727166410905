import React from 'react';

import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill } from 'react-icons/bs'

const AccordionLayout = ({ title, children, index, activeIndex, setActiveIndex }) => {
    const handleSetIndex = (index) => (activeIndex !== index) && setActiveIndex(index);
    const uniqueNumber = new Date().valueOf();
 
    return (
        <>

            <div className="accordion-item bg-white border border-gray-200">
                <h2 className="accordion-header mb-0" id="headingOne">
                    <button className="accordion-button relative flex items-center  w-full  py-4 px-5 text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
                        aria-controls="collapseOne">
                        Accordion Item #1
                    </button>
                </h2>
                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body py-4 px-5">
                        <strong>This is the first item's accordion body.</strong> It is shown by default,
                        until the collapse plugin adds the appropriate classes that we use to style each
                        element. These classes control the overall appearance, as well as the showing and
                        hiding via CSS transitions. You can modify any of this with custom CSS or overriding
                        our default variables. It's also worth noting that just about any HTML can go within
                        the <code>.accordion-body</code>, though the transition does limit overflow.
                    </div>
                </div>
            </div>


            <div onClick={() => handleSetIndex(index)} className='flex w-1/2 justify-between p-2 mt-2 rounded bg-green-400' key={uniqueNumber} >
                <div className='flex'>
                    <div className='text-white font-bold'>{title}</div>
                </div>
                {/* <div className="flex items-center justify-center">
                    {
                        (activeIndex === index)
                            ? <BsFillArrowDownCircleFill className='w-8 h-8' />
                            : <BsFillArrowUpCircleFill className='w-8 h-8' />
                    }
                </div> */}
                <div key={uniqueNumber}  id={uniqueNumber} className="accordion-collapse collapse show" aria-labelledby={"heading" + uniqueNumber } data-bs-parent="#accordionExample">
                    <div className="accordion-body py-4 px-5">{title}</div>
                </div>
            </div>

            {(activeIndex === index) && (
                <div className="shadow-3xl rounded-2xl shadow-cyan-500/50 p-4 mb-6">
                    {children}
                </div>
            )}
        </>
    );
};

export default AccordionLayout;