import React from 'react';

const Youtube = ({ id }) => {
    var opts = {};
    var opt = {};

    opts['src'] = 'https://www.youtube.com/embed/' + encodeURIComponent( id);
    opts['class'] = "youtube";
    opts['allow'] = "accelerometer; autoplay; encrypted-media; gyroscope;";
    opts['frameborder'] = 0;
    opts['allowfullscreen'] = 'allowfullscreen';
    opt['href'] = opts['src'];
    opt['target'] = '_blank';

     
 
    return (
        <>
          

            <div className="video-container">
                <iframe
                    {...opts}>
                        <a  {...opt}>View YouTube video</a>
                    </iframe>
            </div>

        </>
    )
}

export default Youtube