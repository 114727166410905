import React from 'react';
import Image from './image';
import { Link } from 'gatsby';

function docard(type) { 
    type = parseInt(type)
    if (type === 1) { return "card-green" } else
        if (type === 2) { return "card-orange" } else
            if (type === 3) { return "card-blue" } else
            if (type === 4) { return "card-red" }else
            if (type === 5) { return "card-three" }

}

const Fourcard = ({ title = '', image = '', type = "5" , link="#" }) => (
   <Link to={link}>
     <div className={"col md:mr-4 md:px-8 px-4 pt-9 pb-3 mb-1 card " + docard(type) + " flex flex-col hover:translate-y-0.5 hover:drop-shadow-lg "}>
        <div className='mb-2'>
            <Image src={image} classes="mt-6 mb-0 mt-auto" />
        </div> 
         <h2 className="text-base font-semibold monnify-color-3">{title}</h2>  
     </div>
     </Link>
)

export default Fourcard;