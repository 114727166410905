import React from 'react';
import Image from './image';
import { Link } from 'gatsby';

function docard(type) { 
    type = parseInt(type)
    if (type === 1) { return "card-green" } else
        if (type === 2) { return "card-orange" } else
            if (type === 3) { return "card-blue" } else
            if (type === 4) { return "card-red" }else
            if (type === 5) { return "card-three" }

}

const Threecard = ({ title = '', desc = "", image=false, text = '', type = "5" , link="#" }) => (
    <div className={"col md:mr-4 md:px-8 px-4 pt-9 pb-3 mb-1 card " + docard(type) + " flex flex-col  hover:drop-shadow-md hover:scale-105"}>
        { image &&
             <div className='my-2'>
                <Image src={image} classes="my-3 mt-auto" />
            </div> 
        }
         <h2 className="text-base font-semibold monnify-color-3">{title}</h2>
        <div className="text-gray-700 py-1 text-sm mb-2 base-doc-color">{desc}</div>
          <div className='mb-2'>
            <Link to={link}>
                <Image src="viewmore.svg" classes="mt-6 mb-0 mt-auto" /></Link>
        </div> 
     </div>
)

export default Threecard;