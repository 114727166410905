import React, { useEffect, useState } from "react";
import { useStaticQuery, Link, graphql } from "gatsby"

const AutoComplete = props => {
    const data = useStaticQuery(graphql`
    query {
        allMarkdownRemark(sort: { order: DESC, fields: frontmatter___path }) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              frontmatter {
                title
                path
              } 
              html
            }
          }
        }
      }
  `)
//   const { data } = props
   const allPosts = data.allMarkdownRemark.edges

  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  })

  const removeTags = (str, title) => {
      if((str===null) || str === '')
            return false;
      else
            str = "" + str;

      // regular expression
      str = str.replace(/(<[^>]+)/ig, '')
      return str.replace(title, "").substring(0, 200)
  }

  const handleInputChange = event => {
    //console.log(event.target.value)
    const query = event.target.value
    // const { data } = props

    const posts = data.allMarkdownRemark.edges || []

    const filteredData = posts.filter(post => {
      const { title, tags } = post.node.frontmatter
      const description = removeTags(post.node.html, title)
      //console.log(description)
      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase()) ||
        (tags &&
          tags
            .join("")
            .toLowerCase()
            .includes(query.toLowerCase()))
      )
    })

    setState({
      query,
      filteredData,
    })
  }


  const { filteredData, query } = state
  const hasSearchResults = filteredData && query !== emptyQuery
  const posts = hasSearchResults ? filteredData : allPosts


  return (
    <>
      {/* in my site I wrap each page with a Layout and SEO component which have 
    been omitted here for clarity and replaced with a React.fragment --> */}

       <h1 style={{ textAlign: `center` }}>Writing</h1>
       <div className="searchBox">
        <input
          className="searchInput"
          type="text"
          aria-label="Search"
          placeholder="Type to filter posts..."
          onChange={handleInputChange}
        />
      </div>

      {posts.map(({ node }) => { 
        const { title, path, description = '' } = node.frontmatter
        return (
          <article key={path}>
            <header>
              <h2>
                <Link to={path}>{title}</Link>
              </h2>

              <p>k {description}k</p>
            </header>
            <section> eee
              <p
                dangerouslySetInnerHTML={{
                  __html: description || title,
                }}
              />
            </section>
            <hr />
          </article>
        )
      })}
    </>
  )
}

export default AutoComplete

// export const pageQuery = graphql`
// query {
//     allMarkdownRemark(sort: { order: DESC, fields: frontmatter___path }) {
//       edges {
//         node {
//           excerpt(pruneLength: 200)
//           id
//           frontmatter {
//             title
//             path
//           } 
//         }
//       }
//     }
//   }
// `

 