import React from 'react'; 
import Heading from './heading';
 
function convertToSlug(Text) {
    return Text.toLowerCase()
               .replace(/[^\w ]+/g, '')
               .replace(/ +/g, '-').trim();
}

const Changelog = ({ title =  "", tag = false, description = "", arrList = "" }) => (
    <>
          <div className='my-8'>
        <Heading h={6} lp={false}  text={ title }  key={ convertToSlug(title)}  />
        {/* <div className=" mx-2 bg-gray-200 text-sm px-3 cy-2 py-1 rounded inline text-gray-800" > Collections</div><br /> */}
        <p className='mt-1 ' style={{overflowWrap: "break-word"}}>
            {description}
        <ul className="mt-1 list-disc marker:text-orange-500 space-y-2 list-outside ml-4 mx-5 my-3">
        {
            arrList && arrList.length > 0 && ("" + arrList).split("///").map((item) =>{ 
              return(<li>{ item }</li>)  
            })
        }
        </ul>
        </p></div>
    </>
)

export default Changelog;