import React from 'react';
import Image from './image';

const Smartcard = ({ image = "", title = "", text = "", link = "#" }) => (
    <>
        <div className="col-span-3 md:col-span-1 flex flex-row lg:flex mt-3">
            <div>
                <Image src={image} classes="" />
            </div>
            <div className="inline mx-9 flex flex-col ">
                <div className="mb-2">
                    <p className="flex items-left text-md md:text-xl mb-1 font-semibold">{title}</p>
                    <div className=" text-mcyan text-sm md:text-xs mb-2"> <a href={link} target="_blank">{text} </a>
                    </div>
                </div>
            </div>
        </div>

    </>
)

export default Smartcard;


