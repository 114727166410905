import React from 'react';
import ViewButtomImage from "../images/view-arrow.svg"
import RefImage from "../images/reference.svg"
import { Link } from 'gatsby';


const Bigcard = ({ }) => (

    <div className=" mx-2">
        <div className="grid grid-cols-12  monnify-bg-color-2 mt-11 pb-0 px-5">
            <div className="col-span-7 p-6">
                <h1 className="font-semibold text-white text-3xl pb-5 sm:text-xs md:text-3xl md:pt-6">
                    API Reference
                </h1>
                <p className="text-white sm:text-sm">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.</p>
                <Link className="px-3 mt-1" to=""> <img src={ViewButtomImage} classes="mb-0" /></Link>
            </div>
            <div className="col-span-5 mb-0 mt-10 ml-2 a-relative ">
                <img src={RefImage} classes="align-bottom a-bottom b-0 pb-0 " />
            </div>

        </div>

      </div>
)

export default Bigcard;
