import React from 'react';

const Twocolumn = ({ children }) => (
    <><div className="grid grid-cols-12 mt-7">
        {children}
    </div>

      
        </>
)

export default Twocolumn;