import React from 'react';
import { Link } from 'gatsby';
import Docsmenu from '../../data/collection/docsmenu';
import { useLocation } from '@reach/router';

const Breadcrumb = ({ text = false, children }) => {
    const myArray = text.split(",");
    const stoppoint = myArray.length - 1

    let m = Docsmenu.filter(function(item) {
        if (item.name === 'Helium'){ return item }
    });
    const location = useLocation();
    // console.log("locatiof",location)
    let menus = location.pathname.split("/").filter(n => n); 

    // console.log(menus.length, "locatios",menus)

    return (
        <>
            <nav className="flex mt-5 overflow-x-auto break-words" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-3">
                    <li className="inline-flex items-center">
                    <Link to={"/"+ menus[0] + "/"} className="text-mcyan hover:text-gray-900 text-sm inline-flex items-center">
                            Home
                        </Link>
                    </li>

                    { 
                    myArray.map(function (object, i) {
                        return (<li key={i}>
                            <div className="flex items-center">
                                <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                                <Link to={( i == 0 )?"/"+ menus[0] + "/"+ menus[1] + "/":"#"} className={ ((stoppoint == i) ? "hidden md:block text-gray-700" :"text-mcyan" ) + " hover:text-gray-900 ml-1 md:ml-2 text-sm"}>{object}</Link>
                            </div>
                        </li>)
                    })} 
                </ol>
            </nav>
        </>
    )
}
export default Breadcrumb
