import React from 'react';
import Container1 from './container1';
import Heading from './heading';


const Headsm = ({ title = "",  text = "",  }) => (

    
<Container1>
    <div>
        <Heading h="2" text={ title } ></Heading> 
        <p>  { text }  </p>
    </div>
</Container1>
)

export default Headsm;
