import React from "react"
import Highlight from 'react-highlight'
import { Link } from "gatsby";
import 'highlight.js/styles/vs2015.css';



const CodeHighlight = ({ body }) => {
    return (

        <>
            {/* curl, javascript,  python, php, java  */}
            <ul className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tab3"
                role="tablist">
                <li className="nav-item" role="presentation">
                    <Link to="#tabs-home3" className="
      nav-link
      w-full
      block
      font-medium
      text-xs
      leading-tight
      uppercase
      border-x-0 border-t-0 border-b-2 border-transparent
      px-6
      py-3
      my-2
      hover:border-transparent hover:bg-gray-100
      focus:border-transparent
      active
    " id="tabs-home-tab3" data-bs-toggle="pill" data-bs-target="#tabs-home3" role="tab" aria-controls="tabs-home3"
                        aria-selected="true">Home</Link>
                </li>
                <li className="nav-item" role="presentation">
                    <Link to="#tabs-profile3" className="
      nav-link
      w-full
      block
      font-medium
      text-xs
      leading-tight
      uppercase
      border-x-0 border-t-0 border-b-2 border-transparent
      px-6
      py-3
      my-2
      hover:border-transparent hover:bg-gray-100
      focus:border-transparent
    " id="tabs-profile-tab3" data-bs-toggle="pill" data-bs-target="#tabs-profile3" role="tab"
                        aria-controls="tabs-profile3" aria-selected="false">Profile</Link>
                </li>
                <li className="nav-item" role="presentation">
                    <Link to="#tabs-messages3" className="
      nav-link
      w-full
      block
      font-medium
      text-xs
      leading-tight
      uppercase
      border-x-0 border-t-0 border-b-2 border-transparent
      px-6
      py-3
      my-2
      hover:border-transparent hover:bg-gray-100
      focus:border-transparent
    " id="tabs-messages-tab3" data-bs-toggle="pill" data-bs-target="#tabs-messages3" role="tab"
                        aria-controls="tabs-messages3" aria-selected="false">Messages</Link>
                </li>
            </ul>
            <div className="tab-content" id="tabs-tabContent3">
                <div className="tab-pane fade show active" id="tabs-home3" role="tabpanel" aria-labelledby="tabs-home-tab3">
                    <Highlight className='javascript text-xs'>
                        { 
                        `
                        var axios = require('axios');
                        var data = JSON.stringify({
                            "transactionReference": "MNFY|20190514172736|000001",
                        "collectionChannel": "API_NOTIFICATION",
                        "card": {
                            "number": "4111111111111111",
                        "expiryMonth": "09",
                        "expiryYear": "22",
                        "pin": "1234",
                        "cvv": "122"
                        }
                        });

                        var config = {
                            method: 'post',
                        url: 'https://sandbox.monnify.com/api/v1/merchant/cards/charge',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        data : data
                        };

                        axios(config)
                        .then(function (response) {
                            console.log(JSON.stringify(response.data));
})
                        .catch(function (error) {
                            console.log(error);
});
                        `}


                    </Highlight>
                </div>
                <div className="tab-pane fade" id="tabs-profile3" role="tabpanel" aria-labelledby="tabs-profile-tab3">
                    Tab 2 content button version
                </div>
                <div className="tab-pane fade" id="tabs-messages3" role="tabpanel" aria-labelledby="tabs-profile-tab3">
                    Tab 3 content button version
                </div>
            </div>
        </>
    )
}

export default CodeHighlight