import React from 'react'; 

const Container1 = ({children}) => (
    <div className="md:px-7 md:mx-7 mt-8 base-doc-color">
        <div className="md:px-7 mx-2">
            {children}
        </div>
    </div>
)

export default Container1;