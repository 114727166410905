import React from 'react';
import Wrapper from './wrapper';

function wrapper(cols){
    cols = parseInt(cols)
    if (cols === 2){
        return "grid grid-cols-1 md:grid-cols-2 gap-10"
    } else if (cols === 3) {
        return "grid grid-cols-1 md:grid-cols-3 gap-5"
    } else if (cols === 1) {
        return "grid grid-cols-1 md:grid-cols-1 gap-5"
    } else {
        return "grid grid-cols-1 md:grid-cols-4 gap-10"
    }
}

const Cardwrapper = ({ space = 4, cols = 2, children }) => (
    <Wrapper>
        <div className={ "flex-none " + wrapper(cols) }>
                 {children}
         </div>
    </Wrapper>
)

export default Cardwrapper;