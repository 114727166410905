import React from 'react';
import Image from './image';

function docard(type) { 
    type = parseInt(type)
    if (type === 1) { return "card-green" } else
        if (type === 2) { return "card-orange" } else
            if (type === 3) { return "card-blue" } else
                if (type === 4) { return "card-red" }
}

const Halfcard = ({ title = '', desc = "", image = '', type = "1" , link="#", children }) => (
    <div className={"col md:mr-4 md:px-8 px-4 pt-9 pb-0 mb-1 card hover:md:skew-y-1 hover:drop-shadow-md " + docard(type) + " flex flex-col"}>
        <a href={link}>
        <h2 className="text-xl font-semibold">{title}</h2>
        <div className="text-gray-700 py-1 text-sm mb-4">{desc}</div>
          <div>{ children } </div>
        <Image src={image} classes="mt-6 mx-2 mb-0 mt-auto" />
        </a>
    </div>
)

export default Halfcard;