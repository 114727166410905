 
const md = require('markdown-it')({}).use(require('@digitalocean/do-markdownit'), {});

const Msparser = children => md.render( children );
   
                // <div dangerouslySetInnerHTML={{__html: md.render( children )}} />    
                
            


export default Msparser;