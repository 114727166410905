import React from 'react'; 
import Image from './image';

const Masthead = ({ title, description, image }) => {
    return ( <>
    
    <div className="faded-mast-head -mx-3 md:mx-0 md:pb-6 pb-2 mo-b-b-primary ">
                <div className="mx-auto px-3 md:px-9 md:mx-9 pt-7 grid grid-cols-7 md:grid-cols-12 pt-7">
                    <div className="col-span-7 px-3 md:px-9 px-1 py-7 m-2 ml-3  flex justify-center items-center  ">
                       <div>
                        <h1 className="font-semibold text-3xl md:text-5xl md:pt-5 monnify-color-1" style={{fontFamily:"font-family: 'Oxygen Mono', monospace"}}>{ title } 
                        </h1>
                        <p className="py-3 md:py-5 text-base justify-center text-gray-600 md:pr-5">
                          { description }
                        </p>
                        </div>
                    </div>
                    <div className="hidden md:block col-span-5 px-9 py-7 px-1 m-2 ml-4 mr-1 md:mt-11 ">
                        <Image src={image} classes="w-full scale-125 md:h-48 shrink-o" />
                    </div>
                </div>
            </div></>)
     
    }

export default Masthead;